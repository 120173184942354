import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/store";
import {
  fetchVegxHuddleArticleId,
  setTopicArticle,
} from "../../reducers/huddleSlice";
import { fetchUserHuddlesByDateRange } from "../../reducers/userHuddlesSlice";
import { getMonthStart, getMonthEnd } from "@/app/_utils/helper";
import { AppDispatch } from "../../reducers/store";

const useVegxHuddle = (
  open: boolean,
  accessToken: string | null,
  dailyArticle?: { id: string; title: string },
) => {
  const [vegxUserHuddle, setVegxUserHuddle] = useState<any>(undefined);
  const [hasHuddledOnVegx, setHasHuddledOnVegx] = useState(false);
  const [loading, setLoading] = useState(false);
  const [articleMode, setArticleMode] = useState<"daily" | "pick" | "vegx">(
    "daily",
  );

  const vegxHuddleArticleId = useSelector(
    (state: RootState) => state.huddleGroups.vegxHuddleArticleId,
  );
  const vegxHuddleArticle = useSelector((state: RootState) =>
    vegxHuddleArticleId
      ? state.articles.articles[vegxHuddleArticleId]
      : undefined,
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (open && accessToken) {
      setLoading(true);
      const now = new Date();
      const month = now.toLocaleString("en-US", { month: "long" });
      const year = now.getFullYear().toString();
      const from_date = getMonthStart();
      const to_date = getMonthEnd();

      dispatch(fetchVegxHuddleArticleId({ month, year, accessToken }));

      dispatch(
        fetchUserHuddlesByDateRange({
          accessToken,
          query: { from_date, to_date },
        }),
      )
        .unwrap()
        .then((huddles: any[]) => {
          // Iterate to find the matching huddle
          for (const [key, huddle] of Object.entries(huddles)) {
            if (huddle.articleId === vegxHuddleArticleId) {
              setVegxUserHuddle({ ...huddle, joinTime: key });
              setHasHuddledOnVegx(true);
              setLoading(false);
              return;
            }
          }
          setVegxUserHuddle(undefined);
          setHasHuddledOnVegx(false);
        })
        .catch(() => {
          setVegxUserHuddle(undefined);
          setHasHuddledOnVegx(false);
        })
        .finally(() => setLoading(false));
    }
  }, [open, accessToken, dispatch, vegxHuddleArticleId]);

  useEffect(() => {
    if (open) {
      if (
        vegxHuddleArticleId &&
        !hasHuddledOnVegx &&
        vegxHuddleArticle?.title
      ) {
        dispatch(
          setTopicArticle({
            id: vegxHuddleArticleId,
            title: vegxHuddleArticle.title.replace("hidefromsearch", ""),
          }),
        );
        setArticleMode("vegx");
      } else if (!vegxHuddleArticleId || hasHuddledOnVegx) {
        setArticleMode("daily");
        if (dailyArticle) {
          dispatch(setTopicArticle(dailyArticle));
        }
      }
    }
  }, [
    vegxHuddleArticleId,
    vegxHuddleArticle,
    hasHuddledOnVegx,
    dailyArticle,
    open,
  ]);

  return {
    vegxUserHuddle,
    hasHuddledOnVegx,
    loading,
    articleMode,
    setArticleMode,
    vegxHuddleArticleId,
    vegxHuddleArticleTitle: vegxHuddleArticle?.title.replace(
      "hidefromsearch",
      "",
    ),
  };
};

export default useVegxHuddle;
