/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ARTICLE_BY_ID_URL,
  HUDDLES,
  HUDDLES_IMAGES_DELETE,
  HUDDLES_IMAGES_PUT,
  USERS,
  VEGX,
} from "@/app/_consts/internal";
import { RootState } from "./store";
import {
  HuddleLeader,
  StartOrJoinHuddleArgs,
  UndoHuddleArgs,
  TopicArticle,
  EditArgs,
} from "@/app/_types";
import { fetchPhotos } from "@/app/_components/reducers/photoGallerySlice";

interface ImageUpload {
  image: string;
  filename: string;
}

const initialState = {
  isLoading: false,
  huddleGroups: [] as any[],
  error: null as any,
  showAlertModal: false,
  alertModalType: "",
  isAddHuddleModalOpen: false,
  isJoinHuddleModalOpen: false,
  isAddHuddleImageOpen: false,
  selectedHuddle: null as any,
  image: null as ImageUpload | null,
  huddleDay: "",
  huddleId: null,
  huddleTime: null,
  topicArticle: {} as TopicArticle,
  vegxHuddleArticleId: null as string | null,
  isUploadingImage: false,
  numImagesUploaded: 0,
};

export const startOrJoinHuddle = createAsyncThunk<any, StartOrJoinHuddleArgs>(
  "huddle/startOrJoinHuddle",
  async (params, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;

    if (state.huddleGroups.image && params.type !== "joined") {
      const uploadParams = {
        accessToken: params.accessToken,
        action: params.type === "start" ? "create" : "join",
        huddleTime: params.body.huddleTime,
        location: params.body.location,
        ...(params.type === "join" && { huddleId: params.body.huddleId }),
      };
      await dispatch(uploadHuddleImage(uploadParams));
    }

    try {
      let responseData = [];
      if (params.type !== "joined") {
        const response = await axios.put(
          `${ARTICLE_BY_ID_URL}/${params.articleId}/huddle`,
          {
            huddleId: params.body.huddleId,
            huddleTime: params.body.huddleTime,
            location: params.body.location,
          },
          {
            headers: { Authorization: params.accessToken },
          },
        );
        responseData = response.data;
      }

      dispatch(showAlertModal(true));

      if (params.type === "join") {
        dispatch(setAlertModalType("join"));
      } else if (params.type === "start") {
        dispatch(setAlertModalType("start"));
      } else if (params.type === "joined") {
        dispatch(setAlertModalType("joined"));
      }

      dispatch(clearImage());

      setTimeout(() => {
        params.router.push(
          `/reading-list/manifestobychapter/article/${params.articleId}`,
        );
      }, 4000);

      return responseData;
    } catch (error: any) {
      return rejectWithValue(
        error.response ? error.response.data : error.message,
      );
    }
  },
);

export const undoJoinHuddle = createAsyncThunk<any, UndoHuddleArgs>(
  "huddle/undoJoinHuddle",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${ARTICLE_BY_ID_URL}/${params.articleId}/huddle/undo`,
        {
          huddleId: params.body.huddleId,
          huddleTime: params.body.huddleTime,
          joinTime: params.body.joinTime,
          force: true,
        },
        {
          headers: { Authorization: params.accessToken },
        },
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response ? error.response.data : error.message,
      );
    }
  },
);

export const editHuddle = createAsyncThunk<any, EditArgs>(
  "huddle/editHuddle",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${HUDDLES}/${params.huddleId}?huddleTime=${params.huddleTimeOriginal}`,
        {
          huddleTime: params.body.huddleTime,
          location: params.body.location,
          leader: params.body.leader,
          articleId: params.body.articleId,
        },
        {
          headers: { Authorization: params.accessToken },
        },
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response ? error.response.data : error.message,
      );
    }
  },
);

export const fetchHuddleGroups = createAsyncThunk<any, any>(
  "huddle/fetchHuddleGroups",
  async (accessToken) => {
    try {
      const response = await axios.get(`${HUDDLES}/groups`, {
        headers: { Authorization: accessToken },
      });
      return response.data;
    } catch (error: any) {
      return Promise.reject(error.message);
    }
  },
);

export const fetchUsersByLocation = createAsyncThunk<
  HuddleLeader[],
  { accessToken: string; location: string }
>("users/fetchUsersByLocation", async ({ accessToken, location }) => {
  try {
    const response = await axios.get(`${USERS}?location=${location}`, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  } catch (error: any) {
    return Promise.reject(error.message);
  }
});

export const fetchVegxHuddleArticleId = createAsyncThunk<
  string | null,
  { month: string; year: string; accessToken: string }
>("huddle/fetchVegxHuddleArticleId", async ({ year, month, accessToken }) => {
  try {
    const response = await axios.get(`${VEGX}?month=${month}&year=${year}`, {
      headers: { Authorization: accessToken },
    });
    return response.data.huddleArticleId
      ? response.data.huddleArticleId.split("#")[1]
      : null;
  } catch (error: any) {
    return null;
  }
});

export const uploadHuddleImage = createAsyncThunk<any, any>(
  "huddle/uploadImage",
  async (data, { rejectWithValue, getState, dispatch }) => {
    const {
      accessToken,
      action,
      huddleTime,
      location,
      huddleId, // can be null if create
      image: providedImage,
      filename: providedFilename,
    } = data;

    try {
      const state = getState() as RootState;
      const image = providedImage ?? state.huddleGroups.image?.image;
      const filename = providedFilename ?? state.huddleGroups.image?.filename;
      const fileExtension = filename.split(".").pop();

      // Step 1: Get the presigned URL
      const getUrlResponse = await axios.put(
        `${HUDDLES_IMAGES_PUT}/get`,
        {
          filename,
          "content-type": `image/${fileExtension}`,
        },
        { headers: { Authorization: accessToken } },
      );

      const presignedUrl = getUrlResponse.data.s3_presigned_url;

      // Step 2: Upload the image to the presigned URL
      await axios.put(presignedUrl, image, {
        headers: {
          "Content-Type": `image/${fileExtension}`,
        },
      });

      // Step 3: Notify the backend about the uploaded image
      await axios.put(
        `${HUDDLES_IMAGES_PUT}/put`,
        {
          filename: getUrlResponse.data.filename,
          action,
          huddleTime,
          location,
          ...(huddleId ? { huddleId } : {}),
        },
        { headers: { Authorization: accessToken } },
      );

      dispatch(fetchPhotos({ accessToken, params: {} }));

      return { success: true, imageUrl: presignedUrl };
    } catch (error: unknown) {
      return rejectWithValue({ success: false, error: error });
    }
  },
);

export const deleteHuddlePhoto = createAsyncThunk<
  any,
  {
    filename: string;
    huddleId: string;
    huddleTime: string;
    location: string;
    accessToken: string;
  }
>("huddle/deleteImage", async (data, { rejectWithValue }) => {
  const { filename, huddleId, huddleTime, location, accessToken } = data;
  try {
    const response = await axios.put(
      `${HUDDLES_IMAGES_DELETE}`, // Assuming this is the correct endpoint
      {
        filename,
        huddleId,
        huddleTime,
        location,
        deleted: true,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      },
    );
    return response.data;
  } catch (error: unknown) {
    return rejectWithValue(
      error instanceof Error ? error.message : "An error occurred",
    );
  }
});

export const undoDeleteHuddlePhoto = createAsyncThunk<
  any,
  {
    filename: string;
    huddleId: string;
    huddleTime: string;
    location: string;
    accessToken: string;
  }
>("huddle/undoDeleteImage", async (data, { rejectWithValue }) => {
  const { filename, huddleId, huddleTime, location, accessToken } = data;
  try {
    const response = await axios.put(
      `${HUDDLES_IMAGES_DELETE}`,
      {
        filename,
        huddleId,
        huddleTime,
        location,
        deleted: false,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      },
    );
    return response.data; // Expected to be success message or similar confirmation
  } catch (error: unknown) {
    return rejectWithValue(
      error instanceof Error ? error.message : "An error occurred",
    );
  }
});

const huddleSlice = createSlice({
  name: "huddle",
  initialState,
  reducers: {
    showAlertModal: (state, action) => {
      state.showAlertModal = action.payload;
    },
    setAlertModalType: (state, action) => {
      state.alertModalType = action.payload;
    },
    openAddHuddleModal: (state) => {
      state.isAddHuddleModalOpen = true;
    },
    closeAddHuddleModal: (state) => {
      state.isAddHuddleModalOpen = false;
    },
    openJoinHuddleModal: (state) => {
      state.isJoinHuddleModalOpen = true;
    },
    closeJoinHuddleModal: (state) => {
      state.isJoinHuddleModalOpen = false;
    },
    openAddHuddleImageModal: (state) => {
      state.isAddHuddleImageOpen = true;
    },
    closeAddHuddleImageModal: (state) => {
      state.isAddHuddleImageOpen = false;
    },
    clearSelectedHuddle: (state) => {
      state.selectedHuddle = null;
    },
    setSelectedHuddle: (state, action) => {
      state.selectedHuddle = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload;
    },
    clearImage: (state) => {
      state.image = null;
    },
    setHuddleDay: (state, action) => {
      state.huddleDay = action.payload;
    },
    setHuddleId: (state, action) => {
      state.huddleId = action.payload;
    },
    setHuddleTime: (state, action) => {
      state.huddleTime = action.payload;
    },
    setTopicArticle: (state, action) => {
      state.topicArticle = action.payload;
    },
    incrementNumImagesUploaded: (state) => {
      state.numImagesUploaded += 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startOrJoinHuddle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(startOrJoinHuddle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.huddleGroups.push(action.payload);
      })
      .addCase(startOrJoinHuddle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(undoJoinHuddle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(undoJoinHuddle.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(undoJoinHuddle.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchHuddleGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHuddleGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.huddleGroups = action.payload;
      })
      .addCase(fetchHuddleGroups.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchVegxHuddleArticleId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVegxHuddleArticleId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vegxHuddleArticleId = action.payload || "";
      })
      .addCase(fetchVegxHuddleArticleId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(uploadHuddleImage.pending, (state) => {
        state.isUploadingImage = true;
      })
      .addCase(uploadHuddleImage.fulfilled, (state) => {
        state.isUploadingImage = false;
        state.numImagesUploaded += 1;
      })
      .addCase(uploadHuddleImage.rejected, (state, action) => {
        state.isUploadingImage = false;
        state.error = action.error.message;
      });
  },
});

export const {
  showAlertModal,
  setAlertModalType,
  openAddHuddleModal,
  closeAddHuddleModal,
  openJoinHuddleModal,
  closeJoinHuddleModal,
  setSelectedHuddle,
  clearSelectedHuddle,
  setImage,
  clearImage,
  setHuddleDay,
  setHuddleId,
  setHuddleTime,
  setTopicArticle,
  openAddHuddleImageModal,
  closeAddHuddleImageModal,
} = huddleSlice.actions;

export default huddleSlice.reducer;
