const Vegxercise = ({ className }: { className?: string }) => (
  <div className={className}>
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M91.4 25.3H79.1V65.4H91.4V25.3Z" fill="#009DB9" />
      <path d="M80.8 18.5H66.2V72.3H80.8V18.5Z" fill="#009DB9" />
      <path d="M16.9 25.4H4.59998V65.5H16.9V25.4Z" fill="#009DB9" />
      <path d="M29.8999 18.5H15.2999V72.3H29.8999V18.5Z" fill="#009DB9" />
      <path d="M77.4999 37.1H16.7999V53.7H77.4999V37.1Z" fill="#009DB9" />
      <path d="M17.4 33.1H14.9V57.7H17.4V33.1Z" fill="black" />
      <path d="M81.2001 33.1H78.7001V57.7H81.2001V33.1Z" fill="black" />
      <path d="M79.6 44.2H16.4V46.7H79.6V44.2Z" fill="black" />
      <path d="M0 39.6H6V42.1H2.5V48.7H6V51.2H0V39.6Z" fill="black" />
      <path d="M90 39.6H96V51.2H90V48.7H93.5V42.1H90V39.6Z" fill="black" />
    </svg>
  </div>
);

export default Vegxercise;
