"use client";
import { useState, useEffect, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import {
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import SidebarLinks from "./SidebarLinks";
import { cn } from "@/app/_utils/ui";
import HuddleLauncher from "./HuddleLauncher";
import { useOnClickOutside } from "usehooks-ts";
import { useNewHuddleFlow } from "../../hooks/useNewHuddleFlow";
import LaunchHuddle from "../huddle/LaunchHuddle";

const Sidebar = ({
  onCollapse,
}: {
  onCollapse: (collapse: boolean) => void;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    if (!collapse && window.innerWidth < 1280) {
      setCollapse(true);
    }
  });

  const isNewHuddleFlow = useNewHuddleFlow();

  useEffect(() => {
    // Read the collapse state from localStorage
    const storedCollapseState =
      localStorage.getItem("sidebarCollapsed") === "true";
    setCollapse(storedCollapseState);
    onCollapse(storedCollapseState);

    if (window.innerWidth < 640) {
      setCollapse(true);
    }
    setIsLoaded(true);
  }, []);

  const toggleCollapse = () => {
    const newCollapseState = !collapse;
    onCollapse(newCollapseState);
    setCollapse(newCollapseState);

    // Update the collapse state in localStorage
    localStorage.setItem("sidebarCollapsed", newCollapseState.toString());
    window.dispatchEvent(new Event("sidebarCollapse"));
  };

  const closeSidebarOnSmallScreen = () => {
    if (window.innerWidth < 1280) {
      setCollapse(true);
      localStorage.setItem("sidebarCollapsed", "true");
      window.dispatchEvent(new Event("sidebarCollapse"));
    }
  };

  return (
    <>
      <div
        ref={ref}
        id="sidebarNav"
        className={cn(
          "fixed top-0 left-0 w-[263px] h-screen flex flex-col items-center z-[32] bg-dark text-white p-4",
          collapse && "w-[75px] px-0 -translate-x-20 xl:translate-x-0",
          isLoaded ? "transition-all ease-in-out duration-500" : "hidden",
        )}
      >
        <button
          onClick={toggleCollapse}
          className="absolute -top-2 -right-[68px] text-dark xl:hidden bg-white py-5 pl-5 pr-8"
        >
          <MenuOutlined className="scale-110" />
        </button>
        <button
          onClick={toggleCollapse}
          className="absolute -right-12 text-dark hidden xl:block"
        >
          {collapse ? (
            <MenuUnfoldOutlined className="scale-125" />
          ) : (
            <MenuFoldOutlined className="scale-125" />
          )}
        </button>
        <Link
          href="/"
          onClick={closeSidebarOnSmallScreen}
          className={cn(
            "relative z-10 mt-2 mb-4 mx-3 transition-all ease-in-out duration-500",
            collapse ? "w-[51px] h-[39px]" : "w-[64px] h-[48px]",
          )}
        >
          <Image
            width={64}
            height={42}
            alt=""
            src="/assets/images/manifesto/manifesto-book.svg"
          />
        </Link>
        <nav
          id="sidebarNav"
          className={`absolute pt-24 pb-16 top-0 left-0 w-full h-full flex flex-col justify-between py-2  ${collapse && "pointer-events-none xl:pointer-events-auto"}`}
        >
          <SidebarLinks
            collapse={collapse}
            onLinkClick={closeSidebarOnSmallScreen}
          />
          <div className="hidden sm:block">
            {isNewHuddleFlow ? (
              <div className="w-full flex justify-center">
                <LaunchHuddle isCircleButton={true} />
              </div>
            ) : (
              <HuddleLauncher />
            )}
          </div>
        </nav>
      </div>
      {!collapse && (
        <div
          className={cn(
            "fixed z-[31] top-0 left-0 w-screen h-screen bg-transparent xl:hidden",
          )}
        ></div>
      )}
    </>
  );
};

export default Sidebar;
