import React from "react";
import { H2 } from "../typography/h2";
import Image from "next/image";

const HuddleTask = ({
  title,
  subtitle,
  icon,
}: {
  title: string;
  subtitle: string;
  icon: string;
}) => (
  <div className="flex w-full gap-2">
    <div className="w-12 h-12 relative">
      <Image
        fill={true}
        className="object-contain"
        alt=""
        src={`/assets/images/icons/huddle/huddle-icon-${icon}.svg`}
      />
    </div>
    <div className="grow text-left w-full">
      <div className="font-bold">{title}</div>
      <div>{subtitle}</div>
    </div>
  </div>
);

const HuddleComplete = ({ isStarter }: { isStarter: boolean }) => {
  return (
    <>
      <H2>Ready, Set, Huddle!</H2>
      <p className="text-sm text-neutral-700">What’s next?</p>
      <div className="flex flex-col mt-8 gap-4 w-full">
        <HuddleTask
          icon="book"
          title="Review Topic"
          subtitle="Quickly look at the huddle prompt."
        />
        {isStarter && (
          <HuddleTask
            icon="megaphone"
            title="Gather VEGgies"
            subtitle="Quickly chat,  max 2 mins."
          />
        )}
        <HuddleTask
          icon="pic"
          title="Take a Pic!"
          subtitle="Snap a fun group huddle photo."
        />
      </div>
    </>
  );
};

export default HuddleComplete;
