import React, { useState } from "react";
import Image from "next/image";
import AddHuddleImage from "./AddHuddleImage";
import JoinHuddle from "./JoinHuddle";
import StartHuddle from "./StartHuddle";
import { useNewHuddleFlow } from "../../hooks/useNewHuddleFlow";
import LaunchHuddle from "./LaunchHuddle";

const HomeHuddleButtons: React.FC = () => {
  const [isStartHuddleOpen, setIsStartHuddleOpen] = useState(false);
  const [isAddHuddleImageOpen, setIsAddHuddleImageOpen] = useState(false);
  const [isJoinHuddleOpen, setIsJoinHuddleOpen] = useState(false);
  const isNewHuddleFlow = useNewHuddleFlow();

  return (
    <div className="flex flex-col md:flex-row gap-3 md:gap-8">
      {isNewHuddleFlow ? (
        <LaunchHuddle />
      ) : (
        <>
          <JoinHuddle open={isJoinHuddleOpen} setOpen={setIsJoinHuddleOpen} />
          <StartHuddle
            open={isStartHuddleOpen}
            setOpen={setIsStartHuddleOpen}
            buttonLabel="Start"
            buttonIcon={
              <Image
                width={24}
                height={24}
                alt=""
                src="/assets/images/icons/huddle/start-icon.svg"
                className="mr-2"
              />
            }
            dialogTitle="Start a Huddle"
          />
        </>
      )}

      <AddHuddleImage
        open={isAddHuddleImageOpen}
        setOpen={setIsAddHuddleImageOpen}
      />
    </div>
  );
};

export default HomeHuddleButtons;
