import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/store";
import {
  fetchHuddleGroups,
  setHuddleId,
  setHuddleTime,
  setSelectedHuddle,
  startOrJoinHuddle,
} from "../../reducers/huddleSlice";
import { fetchUserHuddles } from "../../reducers/userHuddlesSlice";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/app/_components/ui/dialog";
import LocationSearchInput from "../search/LocationSearchInput";
import { UsergroupAddOutlined } from "@ant-design/icons";
import JoinHuddleItem from "./JoinHuddleItem";
import { Button } from "../buttons/button";
import { AppDispatch } from "../../reducers/store";
import { JoinHuddleIcon } from "../graphics/huddle";
import AnimateIn from "../animation/AnimateIn";
import { HuddleGroup } from "@/app/_types";
import { setSelectedLocation } from "../../reducers/locationDashboardSlice";
import { cn } from "@/app/_utils/ui";
import { useRouter } from "next/navigation";

const JoinHuddleDialog = ({
  open,
  setOpen,
  children,
}: {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  children?: React.ReactNode;
}) => {
  const [searchOther, setSearchOther] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );
  const huddleGroups = useSelector(
    (state: RootState) => state.huddleGroups.huddleGroups,
  );
  const { isLoading } = useSelector((state: RootState) => state.huddleGroups);

  const { selectedLocation } = useSelector(
    (state: RootState) => state.locationDashboard,
  );

  const [filteredHuddleGroups, setFilteredHuddleGroups] = useState<
    HuddleGroup[]
  >([]);

  useEffect(() => {
    const newFilteredHuddleGroups = selectedLocation
      ? huddleGroups.filter(
          (huddle) => huddle.Location === selectedLocation.name,
        )
      : huddleGroups;

    setFilteredHuddleGroups(newFilteredHuddleGroups);

    if (newFilteredHuddleGroups.length > 0) {
      setSearchOther(false);
    }
  }, [selectedLocation, huddleGroups]);

  const handleJoin = async (
    huddleId: string,
    huddleTime: string,
    articleId: string,
    UserJoined: boolean,
  ) => {
    if (accessToken && selectedLocation) {
      const body = {
        huddleId,
        huddleTime: huddleTime.split("+")[0],
        location: selectedLocation?.name,
      };

      dispatch(setHuddleId(huddleId));
      dispatch(setHuddleTime(huddleTime));

      try {
        setError(null);
        await dispatch(
          startOrJoinHuddle({
            articleId,
            body,
            accessToken,
            type: UserJoined ? "joined" : "join",
            router,
          }),
        ).unwrap();

        dispatch(setSelectedHuddle(null));
        setOpen(false);
        await dispatch(fetchUserHuddles(accessToken));
      } catch (err: unknown) {
        setError(err instanceof Error ? err.message : "An error occurred");
      }
    }
  };

  useEffect(() => {
    if (open) {
      setSearchOther(false);
      if (selectedLocation?.name.startsWith("VQ -")) {
        dispatch(setSelectedLocation({ ...selectedLocation, name: "VQ" }));
      }
      dispatch(fetchHuddleGroups(accessToken));
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children ? (
          children
        ) : (
          <Button id="joinHuddle" className="w-full px-4" variant="secondary">
            <UsergroupAddOutlined className="mr-1" /> Join a Huddle
          </Button>
        )}
      </DialogTrigger>
      <DialogContent
        className={
          searchOther || filteredHuddleGroups.length === 0
            ? "overflow-visible"
            : ""
        }
      >
        <div
          id="joinHuddleDialog"
          className="flex flex-col gap-4 items-center justify-center p-10 max-w-[540px]"
        >
          <div className="w-[45px] h-[45px] flex justify-center items-center rounded-full bg-red-secondary">
            <JoinHuddleIcon className="text-white" />
          </div>
          <div className="text-lg -mt-4">Join a Huddle</div>

          {isLoading ? (
            <div className="flex items-center justify-center h-28 w-full">
              {/* Placeholder when loading */}
            </div>
          ) : (
            <AnimateIn
              animateFromClassname="opacity-0"
              animateToClassname="opacity-100"
            >
              {error && (
                <div className="w-full pt-2 pb-6 text-red-600 italic text-center">
                  {error}
                </div>
              )}
              {filteredHuddleGroups.length === 0 || searchOther ? (
                <div className="w-72 mx-auto text-center">
                  {filteredHuddleGroups.length === 0 && (
                    <div className="font-semibold">No recent huddles</div>
                  )}
                  <label className="block text-neutral-700 text-sm mb-1">
                    Search other locations
                  </label>
                  <div className="py-4">
                    <LocationSearchInput
                      initialValue=""
                      autoFocus={true}
                      additionalFilter={(item) => !item.name.includes("VQ -")}
                      onSelectLocation={(location) => {
                        dispatch(setSelectedLocation(location));
                        setSearchOther(false);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={cn(
                    "mb-4 w-full flex flex-col gap-8",
                    filteredHuddleGroups.length > 1 && "divide-y",
                  )}
                >
                  {filteredHuddleGroups.map((huddle, index) => (
                    <JoinHuddleItem
                      key={index}
                      huddleItem={huddle}
                      onJoin={() =>
                        handleJoin(
                          huddle.huddleId,
                          huddle.huddleTime,
                          huddle.ArticleId,
                          Boolean(huddle.UserJoined),
                        )
                      }
                    />
                  ))}
                  <div
                    className={cn(
                      "text-center",
                      filteredHuddleGroups.length > 1 && "pt-8",
                    )}
                  >
                    <button
                      onClick={() => setSearchOther(true)}
                      className="underline text-sm text-blue-tint"
                    >
                      Search Other Huddles
                    </button>
                  </div>
                </div>
              )}
            </AnimateIn>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default JoinHuddleDialog;
