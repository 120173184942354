import React from "react";
import { Button } from "../buttons/button";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { cn } from "@/app/_utils/ui";
import ArticleSearch from "../search/ArticleSearch";
import { ClickableTooltip } from "../Tooltip";
import FadeIn from "../animation/FadeIn";

const HuddleTopicPicker = ({
  articleMode,
  setArticleMode,
  loading,
  dailyArticle,
  topicArticle,
  vegxHuddleArticleId,
  vegxHuddleArticleTitle,
  setTopicArticle,
  dispatch,
  hasHuddledOnVegx,
}: {
  articleMode: "daily" | "pick" | "vegx";
  setArticleMode: (mode: "daily" | "pick" | "vegx") => void;
  loading: boolean;
  dailyArticle: any;
  topicArticle: any;
  vegxHuddleArticleId: string | null;
  vegxHuddleArticleTitle: string | null;
  setTopicArticle: (article: any) => void;
  dispatch: any;
  hasHuddledOnVegx: boolean;
}) => {
  return (
    <>
      <div className="w-full">
        <label className="block text-red-secondary font-display uppercase font-semibold text-lg my-2">
          What’s Your Topic?
        </label>
        {loading ? (
          <div className="h-10 w-full" />
        ) : (
          <FadeIn
            className={cn(
              "grid divide-x-2 divide-neutral-400 rounded-full overflow-hidden border border-neutral-400 gap-px bg-neutral-400 transition-opacity duration-300 delay-300 ease-in-out",
              vegxHuddleArticleId ? "grid-cols-3" : "grid-cols-2",
              vegxHuddleArticleId === null
                ? "opacity-0 pointer-events-none"
                : "opacity-100",
            )}
          >
            {vegxHuddleArticleId && (
              <>
                {hasHuddledOnVegx ? (
                  <ClickableTooltip
                    align="start"
                    trigger={
                      <Button
                        className="w-full border-none rounded-none bg-neutral-100 text-neutral-500 pointer-events-none text-base"
                        variant="outline"
                      >
                        VEGx
                      </Button>
                    }
                    contentClassname="relative top-[35px] overflow-visible"
                    content={
                      <>
                        <p>
                          You’ve run this play already!
                          <br />
                          Call it again next month.
                        </p>
                        <div className="bg-dark w-3 h-3 scale-75 absolute left-4 -bottom-1.5 z-[51] rotate-45" />
                      </>
                    }
                  />
                ) : (
                  <Button
                    onClick={() => {
                      dispatch(
                        setTopicArticle({
                          id: vegxHuddleArticleId,
                          title: vegxHuddleArticleTitle,
                        }),
                      );
                      setArticleMode("vegx");
                    }}
                    variant={articleMode === "vegx" ? "gold" : "outlineSquare"}
                    className="w-full border-none rounded-none hover:bg-gold-tint"
                  >
                    VEGx
                  </Button>
                )}
              </>
            )}
            <Button
              onClick={() => {
                dispatch(setTopicArticle(dailyArticle));
                setArticleMode("daily");
              }}
              variant={articleMode === "daily" ? "gold" : "outlineSquare"}
              className="w-full border-none rounded-none hover:bg-gold-tint"
            >
              Daily Article
            </Button>
            <Button
              onClick={() => setArticleMode("pick")}
              variant={articleMode === "pick" ? "gold" : "outlineSquare"}
              className="w-full border-none rounded-none hover:bg-gold-tint"
            >
              Pick an Article
            </Button>
          </FadeIn>
        )}
      </div>
      {!loading && (
        <>
          {articleMode === "pick" && (
            <div className="w-full z-20 relative">
              <ArticleSearch
                onSelectArticle={(article) => {
                  dispatch(
                    setTopicArticle({
                      id: article.articleId,
                      title: article.title,
                    }),
                  );
                }}
              />
            </div>
          )}
          {topicArticle.title ? (
            <FadeIn className="w-full mt-2">
              <div className="text-sm pb-1 text-neutral-700">Article</div>
              {articleMode === "vegx" ? (
                <ClickableTooltip
                  trigger={
                    <div className="text-sm font-semibold flex gap-1 items-center justify-center">
                      {topicArticle.title.replace("hidefromsearch", "")}
                      <QuestionCircleOutlined className="relative -top-0.5" />
                    </div>
                  }
                  contentClassname="relative top-[30px] overflow-visible"
                  content={
                    <>
                      <div className="max-w-[290px] text-pretty">
                        Quarterback once a month on a new topic related to VEG
                        culture, values, and experiences—share today!
                      </div>
                      <div className="bg-dark w-4 h-4 scale-75 absolute right-1/2 -bottom-1.5 z-[51] translate-x-1.5 rotate-45" />
                    </>
                  }
                />
              ) : (
                <div className="text-sm font-semibold">
                  {topicArticle.title.replace("hidefromsearch", "")}
                </div>
              )}
            </FadeIn>
          ) : (
            <div className="h-12" />
          )}
        </>
      )}
    </>
  );
};

export default HuddleTopicPicker;
